import {
  differenceInDays,
  endOfDay,
  isEqual,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
} from "date-fns";

export function isPeriodOneDay(period: { start: Date; end: Date }) {
  return differenceInDays(period.end, period.start) + 1 <= 3;
}

export function getSelectedPeriod(range: { start: Date; end: Date }) {
  const start = startOfDay(range.start);
  const end = endOfDay(range.end);

  return isEqual(startOfDay(start), startOfDay(new Date()))
    ? "today"
    : isEqual(startOfDay(start), startOfISOWeek(new Date())) &&
        isEqual(endOfDay(end), endOfDay(new Date()))
      ? "this-week"
      : isEqual(startOfDay(start), startOfMonth(new Date())) &&
          isEqual(endOfDay(end), endOfDay(new Date()))
        ? "this-month"
        : null;
}
