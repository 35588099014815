<script setup lang="ts">
import type { View, Range } from "~/server/constants/sale";

import { differenceInDays, getISODay, subDays } from "date-fns";
import { WEEK_DAYS } from "~/data/weekDays";
import { VIEWS } from "~/server/constants/sale";
import { getSelectedPeriod } from "~/utils/sale";
import { TransitionPresets, useTransition } from "@vueuse/core";
import type { CategoryName } from "~/server/constants/product";

const props = defineProps<{
  selectedView: View;
  period: Range;
  category?: CategoryName;
  cashierId?: string;
  productIds?: string;
  paymentMethodId?: string;
}>();

const emit = defineEmits<{
  "update:selectedView": [value: View];
}>();

const storeId = computed(() => useCurrentStore().value?._id);
const periodStart = computed(() => props.period.start.toISOString());
const periodEnd = computed(() => props.period.end.toISOString());
const category = computed(() => props.category);
const cashierId = computed(() => props.cashierId);
const productIds = computed(() => props.productIds);
const paymentMethodId = computed(() => props.paymentMethodId);
const query = {
  storeId,
  periodStart,
  periodEnd,
  category,
  cashierId,
  productIds,
  paymentMethodId,
};

const { data: totalSaleCount, status: totalSaleCountStatus } =
  await useLazyFetch("/api/sales/sale-count", {
    query,
    server: false,
  });
const { data: totalSalesRevenue, status: totalSalesRevenueStatus } =
  await useLazyFetch("/api/sales/sales-revenue", {
    query,
    server: false,
  });
const { data: averageRevenuePerSale, status: averageRevenuePerSaleStatus } =
  await useLazyFetch("/api/sales/average-revenue-per-sale", {
    query,
    server: false,
  });

const dataPending = computed(() => {
  switch (props.selectedView) {
    case "sales":
      return (
        totalSaleCountStatus.value === "idle" ||
        totalSaleCountStatus.value === "pending"
      );
    case "revenue":
      return (
        totalSalesRevenueStatus.value === "idle" ||
        totalSalesRevenueStatus.value === "pending"
      );
    case "average-revenue":
      return (
        averageRevenuePerSaleStatus.value === "idle" ||
        averageRevenuePerSaleStatus.value === "pending"
      );
    default:
      return false;
  }
});

const { t, locale } = useI18n({
  useScope: "local",
});

const periodLength = computed(() => {
  const selectedPeriod = getSelectedPeriod(props.period);

  const oneDayMessage = (date: Date) =>
    `${t("the")} ${locale.value === "en" ? "previous" : ""} ${t(WEEK_DAYS[getISODay(date) - 1])} ${locale.value === "fr" ? "précédent" : ""}`;

  switch (selectedPeriod) {
    case "today":
      return oneDayMessage(new Date());
    case "this-week":
      return t("previous-week");
    case "this-month":
      return t("previous-month");
  }

  const daysCount = differenceInDays(props.period.end, props.period.start) + 1;

  if (daysCount === 1) {
    return oneDayMessage(props.period.start);
  }

  if (daysCount < 7) {
    return `${t("previous-week")} (${t(WEEK_DAYS[getISODay(subDays(props.period.start, 7)) - 1])} - ${t(WEEK_DAYS[getISODay(subDays(props.period.end, 7)) - 1])})`;
  }

  return `${t("the", daysCount)} ${locale.value === "en" ? "previous" : ""} ${daysCount > 1 ? daysCount : ""} ${t("day", daysCount)} ${locale.value === "fr" ? "précédent" + (daysCount > 1 ? "s" : "") : ""}`;
});

const duration = 1500;
const totalSaleCountBaseNumber = ref(0);
const totalSaleCountNumber = useTransition(totalSaleCountBaseNumber, {
  duration,
  transition: TransitionPresets.easeOutCirc,
});
const totalSalesRevenueBaseNumber = ref(0);
const totalSalesRevenueNumber = useTransition(totalSalesRevenueBaseNumber, {
  duration,
  transition: TransitionPresets.easeOutCirc,
});
const averageRevenuePerSaleBaseNumber = ref(0);
const averageRevenuePerSaleNumber = useTransition(
  averageRevenuePerSaleBaseNumber,
  {
    duration,
    transition: TransitionPresets.easeOutCirc,
  },
);

const getData = (view: View) => {
  switch (view) {
    case "sales":
      return totalSaleCountNumber;
    case "revenue":
      return totalSalesRevenueNumber;
    case "average-revenue":
      return averageRevenuePerSaleNumber;
  }
};

watchEffect(() => {
  if (totalSaleCount?.value !== undefined)
    totalSaleCountBaseNumber.value = totalSaleCount.value?.value ?? 0;
});
watchEffect(() => {
  if (totalSalesRevenue?.value !== undefined)
    totalSalesRevenueBaseNumber.value = totalSalesRevenue.value?.value ?? 0;
});
watchEffect(() => {
  if (averageRevenuePerSale?.value !== undefined)
    averageRevenuePerSaleBaseNumber.value =
      averageRevenuePerSale.value?.value ?? 0;
});
</script>

<template>
  <UIScrollArea
    class="max-w-[calc(100vw-32px)] whitespace-nowrap rounded-t-xl bg-surface sm:max-w-[calc(100vw-80px)] md:max-w-full"
  >
    <ul class="flex w-fit border-b border-outline/40">
      <li v-for="view in VIEWS" :key="view" class="w-full">
        <button
          @click="
            () => {
              emit('update:selectedView', view);
              return navigateTo({
                query: {
                  ...$route.query,
                  view,
                },
              });
            }
          "
          class="flex min-w-48 flex-1 flex-col gap-3 border-r border-outline/40 px-6 py-4 focus-visible:outline-black md:min-w-60"
          :class="[
            view === selectedView
              ? 'border-b-2 border-b-on-surface bg-surface-container-lowest'
              : 'bg-surface-container-lowest/40',
          ]"
        >
          <span class="font-medium text-on-surface/80 first-letter:capitalize">
            {{ t(view, { unit: useCurrency().value }) }}
          </span>

          <div class="flex items-center gap-4">
            <UISkeleton v-if="dataPending" class="h-9 w-24" />
            <span
              v-else
              class="posthog-block text-3xl font-bold"
              :class="
                view === selectedView ? 'text-on-surface' : 'text-on-surface/70'
              "
            >
              {{
                view === "average-revenue"
                  ? getData(view).value === 0
                    ? 0
                    : getData(view).value.toFixed(2)
                  : numberFormat(Number(getData(view).value.toFixed(0)))
              }}
            </span>

            <UITooltipProvider v-if="view === 'sales' && totalSaleCount?.trend">
              <UITooltip>
                <UITooltipTrigger as-child>
                  <span
                    class="rounded-md px-2 py-1 text-sm font-medium"
                    :class="
                      totalSaleCount?.trend > 0
                        ? 'bg-green-800/10 text-green-800'
                        : 'bg-red-800/10 text-red-700'
                    "
                  >
                    {{ totalSaleCount?.trend > 0 ? "+" : "-"
                    }}{{ Math.abs(Math.round(totalSaleCount?.trend)) }}%
                  </span>
                </UITooltipTrigger>
                <UITooltipContent
                  side="bottom"
                  class="w-fit max-w-[28ch] text-balance p-4"
                >
                  <p class="text-sm">
                    {{
                      t("trendDescription", {
                        content: `${Math.abs(Math.round(totalSaleCount?.trend))}% ${totalSaleCount?.trend > 0 ? t("more") : t("less")}`,
                        subject: t("sales"),
                        periodLength,
                      })
                    }}
                  </p>
                </UITooltipContent>
              </UITooltip>
            </UITooltipProvider>

            <UITooltipProvider
              v-if="view === 'revenue' && totalSalesRevenue?.trend"
            >
              <UITooltip>
                <UITooltipTrigger as-child>
                  <span
                    class="rounded-md px-2 py-1 text-sm font-medium"
                    :class="
                      totalSalesRevenue?.trend > 0
                        ? 'bg-green-800/10 text-green-800'
                        : 'bg-red-800/10 text-red-700'
                    "
                  >
                    {{ totalSalesRevenue?.trend > 0 ? "+" : "-"
                    }}{{ Math.abs(totalSalesRevenue?.trend) }}%
                  </span>
                </UITooltipTrigger>
                <UITooltipContent
                  side="bottom"
                  class="w-fit max-w-[28ch] text-balance p-4"
                >
                  <p class="text-sm">
                    {{
                      t("trendDescription", {
                        content: `${Math.abs(Math.round(totalSalesRevenue?.trend))}% ${totalSalesRevenue?.trend > 0 ? t("more") : t("less")}`,
                        subject: t("revenue"),
                        periodLength,
                      })
                    }}
                  </p>
                </UITooltipContent>
              </UITooltip>
            </UITooltipProvider>

            <UITooltipProvider
              v-if="view === 'average-revenue' && averageRevenuePerSale?.trend"
            >
              <UITooltip>
                <UITooltipTrigger as-child>
                  <span
                    class="rounded-md px-2 py-1 text-sm font-medium"
                    :class="
                      averageRevenuePerSale?.trend > 0
                        ? 'bg-green-800/10 text-green-800'
                        : 'bg-red-800/10 text-red-700'
                    "
                  >
                    {{ averageRevenuePerSale?.trend > 0 ? "+" : "-"
                    }}{{ Math.abs(averageRevenuePerSale?.trend) }}%
                  </span>
                </UITooltipTrigger>
                <UITooltipContent
                  side="bottom"
                  class="w-fit max-w-[28ch] text-balance p-4"
                >
                  <p class="text-sm">
                    {{
                      t("trendDescription", {
                        content: `${Math.abs(averageRevenuePerSale?.trend)}% ${averageRevenuePerSale?.trend > 0 ? t("more") : t("less")}`,
                        subject: t("average-revenue"),
                        periodLength,
                      })
                    }}
                  </p>
                </UITooltipContent>
              </UITooltip>
            </UITooltipProvider>
          </div>
        </button>
      </li>
    </ul>

    <UIScrollAreaBar orientation="horizontal" />
  </UIScrollArea>
</template>

<i18n lang="json">
{
  "en": {
    "sales": "sales",
    "revenue": "revenue",
    "average-revenue": "average revenue",
    "trendDescription": "{content} {subject} than the {periodLength}",
    "more": "more",
    "less": "less",
    "yesterday": "yesterday",
    "previous-week": "previous week",
    "previous-month": "previous month",
    "the": "the",
    "day": "day | days",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday"
  },
  "fr": {
    "sales": "ventes",
    "revenue": "chiffre d'affaire",
    "average-revenue": "revenu moyen",
    "trendDescription": "{content} de {subject} que {periodLength}",
    "more": "plus",
    "less": "moins",
    "yesterday": "hier",
    "previous-week": "la semaine précédente",
    "previous-month": "le mois précédent",
    "the": "le | les",
    "day": "jour | jours",
    "monday": "lundi",
    "tuesday": "mardi",
    "wednesday": "mercredi",
    "thursday": "jeudi",
    "friday": "vendredi",
    "saturday": "samedi",
    "sunday": "dimanche"
  }
}
</i18n>
