<script setup lang="ts">
import {
  differenceInDays,
  differenceInHours,
  format,
  subDays,
  subHours,
} from "date-fns";
import type { CategoryName } from "~/server/constants/product";
import type { Range, View } from "~/server/constants/sale";
import { isPeriodOneDay } from "~/utils/sale";

type Product = {
  id: string;
  name: string;
};

type Cashier = {
  id?: string;
  name?: string;
};
type PaymentMethod = {
  id?: string;
  name?: string;
};
const props = defineProps<{
  view: View;
  period: Range;
  category: CategoryName | undefined;
  products: Product[] | undefined;
  cashier: Cashier | undefined;
  paymentMethod: PaymentMethod | undefined;
}>();

const emit = defineEmits<{
  "update:category": [value: undefined];
  "update:products": [value: Product[]];
  "update:cashier": [value: undefined];
  "update:paymentMethod": [value: undefined];
}>();

const storeId = computed(() => useCurrentStore().value?._id);
const periodStart = computed(() => props.period.start.toISOString());
const periodEnd = computed(() => props.period.end.toISOString());
const category = computed(() => props.category);
const cashierId = computed(() => props.cashier?.id);
const paymentMethodId = computed(() => props.paymentMethod?.id);
const productIds = computed(() =>
  props.products && props.products.length > 0
    ? JSON.stringify(props.products?.map((product) => product.id))
    : undefined,
);

const query = {
  storeId,
  periodStart,
  periodEnd,
  category,
  cashierId,
  productIds,
  paymentMethodId,
};

const { data: saleCountByTime } = await useLazyFetch(
  "/api/sales/sale-count/by-time",
  {
    query,
    transform: transformData,
    server: false,
  },
);
const { data: salesRevenueByTime } = await useLazyFetch(
  "/api/sales/sales-revenue/by-time",
  {
    query,
    transform: transformData,
    server: false,
  },
);
const { data: averageRevenuePerSaleByTime } = await useLazyFetch(
  "/api/sales/average-revenue-per-sale/by-time",
  {
    query,
    transform: transformData,
    server: false,
  },
);

const periodDaysCount = () =>
  differenceInDays(props.period.end, props.period.start) + 1;

function transformData(data: { x: string; y: number }[]) {
  const result: { x: string; y: number }[] = [];
  let resultLength = 0;
  let lastHour: Date;

  if (periodDaysCount() <= 3) {
    if (data.length >= 1) {
      resultLength =
        Math.abs(
          differenceInHours(
            new Date(data[data.length - 1].x),
            new Date(data[0].x),
          ),
        ) + 2;

      lastHour = new Date(data[data.length - 1].x);
    } else {
      // Handle empty data case
      resultLength =
        differenceInHours(props.period.end, props.period.start) + 2;
      lastHour = props.period.end;
    }

    for (let i = 0; i < resultLength; i++) {
      const date = format(subHours(lastHour, i), "MM/dd/y HH:00");

      const foundElement = data.find((el) => el.x === date);

      if (foundElement) {
        result.push({ x: foundElement.x, y: foundElement.y });
      } else {
        result.push({ x: date, y: 0 });
      }
    }
  } else {
    resultLength = differenceInDays(props.period.end, props.period.start) + 1;

    for (let i = 0; i < resultLength; i++) {
      const date = format(subDays(props.period.end, i), "MM/dd/yyyy");

      const foundElement = data.find((el) => el.x === date);

      if (foundElement) {
        result.push({ x: foundElement.x, y: foundElement.y });
      } else {
        result.push({ x: date, y: 0 });
      }
    }
  }

  return result;
}

const route = useRoute();

async function deleteCategory() {
  emit("update:category", undefined);
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      category: undefined,
    },
  });
}

async function deleteCashier() {
  emit("update:cashier", undefined);
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      cashierId: undefined,
      cashierName: undefined,
    },
  });
}

async function deleteProduct() {
  emit("update:products", []);
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      products: undefined,
    },
  });
}

async function deletePaymentMethod() {
  emit("update:paymentMethod", undefined);
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      paymentMethodId: undefined,
      paymentMethodName: undefined,
    },
  });
}

async function deleteAll() {
  await deleteCategory();
  await deleteCashier();
  await deleteProduct();
  await deletePaymentMethod();
}

const { t } = useI18n({
  useScope: "local",
});
</script>

<template>
  <div
    class="relative min-h-[413px] rounded-b-xl bg-surface-container-lowest p-4 sm:p-6 md:w-full"
  >
    <div
      class="absolute right-8 top-0 z-10 flex max-w-full gap-2 overflow-auto pb-6 pl-6 pr-2 pt-4"
    >
      <button
        v-if="cashier?.id"
        @click="deleteCashier"
        style="
          transition:
            transform 0.15s ease,
            opacity 0.15s ease;
          transform: translateY(60%);
          transition-delay: 0.01s;
        "
        class="flex shrink-0 items-center gap-1 rounded-md px-2 py-1 text-sm text-secondary outline outline-1 outline-outline/30 hover:bg-on-surface/[.08] hover:text-primary"
        :class="cashier?.id && 'translate-y-[60%] opacity-100'"
      >
        <span>
          {{ t("cashier") }}:
          <span class="font-semibold">{{ cashier.name }}</span>
        </span>
        <Icon name="mdi:close" class="size-4" />
      </button>

      <button
        v-if="category"
        @click="deleteCategory"
        style="
          transition:
            transform 0.15s ease,
            opacity 0.15s ease;
          transform: translateY(60%);
          transition-delay: 0.01s;
        "
        class="flex shrink-0 items-center gap-1 rounded-md px-2 py-1 text-sm text-secondary outline outline-1 outline-outline/30 hover:bg-on-surface/[.08] hover:text-primary"
        :class="category && 'translate-y-[60%] opacity-100'"
      >
        <span>
          {{ t("category") }}:
          <span class="font-semibold">{{ category }}</span>
        </span>
        <Icon name="mdi:close" class="size-4" />
      </button>

      <button
        v-for="product in products"
        :key="product.id"
        @click="
          emit(
            'update:products',
            products ? products.filter((p) => p.id !== product.id) : [],
          )
        "
        style="
          transition:
            transform 0.15s ease,
            opacity 0.15s ease;
          transform: translateY(60%);
          transition-delay: 0.01s;
        "
        class="flex shrink-0 items-center gap-1 rounded-md px-2 py-1 text-sm text-secondary outline outline-1 outline-outline/30 hover:bg-on-surface/[.08] hover:text-primary"
        :class="
          products && products?.length > 0 && 'translate-y-[60%] opacity-100'
        "
      >
        <span>
          {{ t("product") }}:
          <span class="font-semibold">{{ product?.name }}</span>
        </span>
        <Icon name="mdi:close" class="size-4" />
      </button>

      <button
        v-if="paymentMethod?.id"
        @click="deletePaymentMethod"
        style="
          transition:
            transform 0.15s ease,
            opacity 0.15s ease;
          transform: translateY(60%);
          transition-delay: 0.01s;
        "
        class="flex shrink-0 items-center gap-1 rounded-md px-2 py-1 text-sm text-secondary outline outline-1 outline-outline/30 hover:bg-on-surface/[.08] hover:text-primary"
        :class="paymentMethod?.id && 'translate-y-[60%] opacity-100'"
      >
        <span>
          {{ t("paymentMethod") }}:
          <span class="font-semibold">{{ paymentMethod.name }}</span>
        </span>
        <Icon name="mdi:close" class="size-4" />
      </button>

      <button
        v-if="
          category ||
          cashier?.id ||
          (products && products?.length > 0) ||
          paymentMethod?.id
        "
        @click="deleteAll"
        style="
          transition:
            transform 0.15s ease,
            opacity 0.15s ease;
          transform: translateY(60%);
          transition-delay: 0.01s;
        "
        class="flex shrink-0 items-center gap-1 rounded-md px-2 py-1 text-sm text-secondary outline outline-1 outline-outline/30 hover:bg-on-surface/[.08] hover:text-primary"
        :class="
          (category ||
            cashier?.id ||
            (products && products?.length > 0) ||
            paymentMethod?.id) &&
          'translate-y-[60%] opacity-100'
        "
      >
        <span class="font-medium">
          {{ t("clear") }}
        </span>
      </button>
    </div>

    <ChartsArea
      :is-today="isPeriodOneDay(period)"
      :show-fraction="view === 'average-revenue'"
      :series="
        view === 'revenue'
          ? [
              {
                name: t('salesRevenue', {
                  unit: useCurrency().value,
                }),
                data: salesRevenueByTime ?? [],
              },
            ]
          : view === 'sales'
            ? [{ name: t('saleCount'), data: saleCountByTime ?? [] }]
            : [
                {
                  name: t('averageRevenuePerSale', {
                    unit: useCurrency().value,
                  }),
                  data: averageRevenuePerSaleByTime ?? [],
                },
              ]
      "
      :class="[
        (category ||
          cashier?.id ||
          (products && products?.length > 0) ||
          paymentMethod?.id) &&
          'pt-12',
      ]"
    />
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "category": "Category",
    "cashier": "Cashier",
    "product": "Product",
    "clear": "Clear All",
    "saleCount": "Sale Count",
    "salesRevenue": "Revenue ({unit})",
    "averageRevenuePerSale": "Average revenue per sale ({unit})",
    "paymentMethod": "Payment Method"
  },
  "fr": {
    "category": "Catégorie",
    "cashier": "Caissier",
    "product": "Produit",
    "clear": "Supprimer Tous",
    "saleCount": "Nombre de Ventes",
    "salesRevenue": "Revenu ({unit})",
    "averageRevenuePerSale": "Revenu moyen par vente ({unit})",
    "paymentMethod": "Moyen de paiement"
  }
}
</i18n>
