import { startOfDay } from "date-fns";

export const VIEWS = ["sales", "revenue", "average-revenue"] as const;

export type View = (typeof VIEWS)[number];

export const PERIODS = ["today", "this-week", "this-month"] as const;

export type Period = (typeof PERIODS)[number];

export const DEFAULT_PERIOD = () => ({
  start: startOfDay(new Date()),
  end: new Date(),
});

export type Range = {
  start: Date;
  end: Date;
};

export const DEFAULT_POSITIVE_TREND_THRESHOLD = 10;
export const DEFAULT_NEGATIVE_TREND_THRESHOLD = -10;
