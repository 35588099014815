<script setup lang="ts">
import type { CategoryName } from "~/server/constants/product";
import type { View, Range } from "~/server/constants/sale";
import {
  averageRevenueByCategoryOutputSchema,
  saleCountByCategoryOutputSchema,
  salesRevenueByCategoryOutputSchema,
} from "~/validations/sale";

type Cashier = {
  id?: string;
  name?: string;
};
type PaymentMethod = {
  id?: string;
  name?: string;
};

const props = defineProps<{
  view: View;
  period: Range;
  category: CategoryName | undefined;
  cashier: Cashier | undefined;
  productIds?: string;
  paymentMethod: PaymentMethod | undefined;
}>();

const emit = defineEmits<{
  "update:category": [value: CategoryName | undefined];
}>();

const storeId = computed(() => useCurrentStore().value?._id);
const periodStart = computed(() => props.period.start.toISOString());
const periodEnd = computed(() => props.period.end.toISOString());
const category = computed(() => props.category);
const cashierId = computed(() => props.cashier?.id);
const productIds = computed(() => props.productIds);
const paymentMethodId = computed(() => props.paymentMethod?.id);

const topCategoriesQuery = {
  periodStart,
  periodEnd,
  cashierId,
  productIds,
  storeId,
  paymentMethodId,
};

const { data: saleCountByCategory, status: saleCountByCategoryStatus } =
  await useLazyFetch("/api/sales/sale-count/by-category", {
    transform: (data) => saleCountByCategoryOutputSchema.parse(data),
    query: topCategoriesQuery,
    server: false,
  });
const { data: salesRevenueByCategory, status: salesRevenueByCategoryStatus } =
  await useLazyFetch("/api/sales/sales-revenue/by-category", {
    transform: (data) => salesRevenueByCategoryOutputSchema.parse(data),
    query: topCategoriesQuery,
    server: false,
  });
const {
  data: averageRevenuePerSaleByCategory,
  status: averageRevenuePerSaleByCategoryStatus,
} = await useLazyFetch("/api/sales/average-revenue-per-sale/by-category", {
  transform: (data) => averageRevenueByCategoryOutputSchema.parse(data),
  query: topCategoriesQuery,
  server: false,
});

const currentStore = useCurrentStore();

const route = useRoute();

async function toggleCategory(label: CategoryName) {
  if (props.category === label) {
    deleteCategory();
    return;
  }

  emit("update:category", label);
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      category: label,
    },
  });
}

async function deleteCategory() {
  emit("update:category", undefined);
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      category: undefined,
    },
  });
}

const dataPending = computed(() => {
  switch (props.view) {
    case "sales":
      return (
        saleCountByCategoryStatus.value === "idle" ||
        saleCountByCategoryStatus.value === "pending"
      );
    case "revenue":
      return (
        salesRevenueByCategoryStatus.value === "idle" ||
        salesRevenueByCategoryStatus.value === "pending"
      );
    case "average-revenue":
      return (
        averageRevenuePerSaleByCategoryStatus.value === "idle" ||
        averageRevenuePerSaleByCategoryStatus.value === "pending"
      );
    default:
      return false;
  }
});

const { t, locale } = useI18n({
  useScope: "local",
});

const { isScrolling } = useScroll(window);
</script>

<template>
  <div
    class="overflow-hidden rounded-xl bg-surface-container-lowest outline outline-1 outline-outline/20"
  >
    <div
      class="flex items-center justify-between border-b border-outline/40 p-5 sm:p-6"
    >
      <div class="relative grow">
        <div
          class="flex items-center gap-2 transition-transform delay-0 duration-150 ease-in-out"
          :class="category && 'translate-y-[-45%]'"
        >
          <p class="font-medium">
            {{ t("title") }}
          </p>

          <UIPopover>
            <UIPopoverTrigger as-child>
              <button
                class="flex items-center rounded p-1 hover:bg-on-surface/[.08] focus-visible:bg-on-surface/[.08] focus-visible:outline-none"
                aria-label="Info"
              >
                <Icon
                  name="mdi:information"
                  class="size-4 text-on-surface/50"
                />
              </button>
            </UIPopoverTrigger>
            <UIPopoverContent class="w-fit max-w-[32ch]">
              <p v-if="locale === 'fr'" class="text-sm">
                Les catégories
                <!-- <span v-if="productName">
                  de
                  <span class="font-medium first-letter:capitalize">{{
                    productName
                  }}</span>
                </span>
                <span v-if="productName && cashier"> et </span>
                <span v-if="cashier">
                  vendus par
                  <span class="font-medium capitalize">{{ cashier.name }}</span>
                </span> -->
                qui ont le plus
                {{
                  view === "revenue"
                    ? "de revenus"
                    : view === "sales"
                      ? "grand nombre de ventes"
                      : "revenu moyen par vente"
                }}
              </p>

              <p v-else class="text-sm">
                The categories
                <!-- <span v-if="productName">
                  of
                  <span class="font-medium capitalize">{{ productName }}</span>
                </span>
                <span v-if="productName && cashier"> and </span>
                <span v-if="cashier">
                  sold by
                  <span class="font-medium capitalize">{{ cashier.name }}</span>
                </span> -->
                that have the most
                {{
                  view === "revenue"
                    ? "revenue"
                    : view === "sales"
                      ? "sales count per sale"
                      : "average revenue per sale"
                }}
              </p>
            </UIPopoverContent>
          </UIPopover>
        </div>

        <button
          v-if="category"
          @click="deleteCategory"
          style="
            transition:
              transform 0.15s ease,
              opacity 0.15s ease;
            transform: translateY(60%);
            transition-delay: 0.01s;
          "
          class="absolute left-[-7px] top-0 flex items-center gap-1 rounded-lg px-2 py-1 text-sm text-secondary hover:bg-on-surface/[.08] hover:text-primary"
          :class="category && 'translate-y-[60%] opacity-100'"
        >
          <span>{{ t("clearFilter") }}</span>
          <Icon name="mdi:close" class="size-4" />
        </button>
      </div>

      <p class="text-sm uppercase text-on-surface/80">
        {{ t(view) }}
      </p>
    </div>

    <div class="h-[19.5rem] overflow-auto px-2 py-4">
      <ul v-if="dataPending" class="space-y-4 px-4 py-2">
        <li v-for="i in 5" :key="i">
          <UISkeleton class="h-10 w-full" />
        </li>
      </ul>

      <ul
        v-else-if="
          view === 'sales' &&
          saleCountByCategory &&
          saleCountByCategory.length > 0
        "
        class="space-y-5"
      >
        <li v-for="{ name, value, trend } in saleCountByCategory" :key="name">
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="() => toggleCategory(name)"
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="category === name && 'bg-on-surface/[.08]'"
              >
                <div class="flex items-center gap-2">
                  <span>
                    {{ name }}
                  </span>
                  <span
                    v-if="
                      currentStore?.positiveTrendThreshold !== undefined &&
                      currentStore?.positiveTrendThreshold !== undefined
                    "
                  >
                    <SalesTrendIndicator
                      :trend="trend"
                      :positiveTrendThreshold="
                        currentStore?.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore?.negativeTrendThreshold
                      "
                    />
                  </span>
                </div>

                <span class="font-medium">
                  {{ numberFormat(value) }}
                </span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p v-if="locale === 'fr'">
                  Cliquer pour
                  {{ category === name ? "effacer" : "filtrer" }}
                </p>

                <p v-if="locale === 'en'">
                  Click to
                  {{ category === name ? "clear" : "filter" }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>

      <ul
        v-else-if="
          view === 'revenue' &&
          salesRevenueByCategory &&
          salesRevenueByCategory.length > 0
        "
        class="space-y-5"
      >
        <li
          v-for="{ name, value, trend } in salesRevenueByCategory"
          :key="name"
        >
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="() => toggleCategory(name)"
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="category === name && 'bg-on-surface/[.08]'"
              >
                <div class="flex items-center gap-2">
                  <span>
                    {{ name }}
                  </span>
                  <span
                    v-if="
                      currentStore?.positiveTrendThreshold !== undefined &&
                      currentStore?.positiveTrendThreshold !== undefined
                    "
                  >
                    <SalesTrendIndicator
                      :trend="trend"
                      :positiveTrendThreshold="
                        currentStore?.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore?.negativeTrendThreshold
                      "
                    />
                  </span>
                </div>

                <span class="font-medium">
                  {{ numberFormat(value) }}
                </span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p v-if="locale === 'fr'">
                  Cliquer pour
                  {{ category === name ? "effacer" : "filtrer" }}
                </p>

                <p v-if="locale === 'en'">
                  Click to
                  {{ category === name ? "clear" : "filter" }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>

      <ul
        v-else-if="
          view === 'average-revenue' &&
          averageRevenuePerSaleByCategory &&
          averageRevenuePerSaleByCategory.length > 0
        "
        class="space-y-5"
      >
        <li
          v-for="{ name, value, trend } in averageRevenuePerSaleByCategory"
          :key="name"
        >
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="() => toggleCategory(name)"
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="category === name && 'bg-on-surface/[.08]'"
              >
                <div class="flex items-center gap-2">
                  <span>
                    {{ name }}
                  </span>
                  <span
                    v-if="
                      currentStore?.positiveTrendThreshold !== undefined &&
                      currentStore?.positiveTrendThreshold !== undefined
                    "
                  >
                    <SalesTrendIndicator
                      :trend="trend"
                      :positiveTrendThreshold="
                        currentStore?.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore?.negativeTrendThreshold
                      "
                    />
                  </span>
                </div>

                <span class="font-medium">
                  {{ averageRevenueFormat(value) }}
                </span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p v-if="locale === 'fr'">
                  Cliquer pour
                  {{ category === name ? "effacer" : "filtrer" }}
                </p>

                <p v-if="locale === 'en'">
                  Click to
                  {{ category === name ? "clear" : "filter" }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Top Categories",
    "sales": "Sales",
    "revenue": "Revenue",
    "average-revenue": "Average revenue",
    "clearFilter": "Clear Filter"
  },
  "fr": {
    "title": "Top Catégories",
    "sales": "Ventes",
    "revenue": "Revenu",
    "average-revenue": "Revenu moyen",
    "clearFilter": "Supprimer Filtre"
  }
}
</i18n>
