<script setup lang="ts">
import type { CategoryName } from "~/server/constants/product";
import type { View, Range } from "~/server/constants/sale";
import {
  averageRevenueByCashierOutputSchema,
  saleCountByCashierOutputSchema,
  salesRevenueByCashierOutputSchema,
} from "~/validations/sale";

type Cashier = {
  id?: string;
  name?: string;
};
type PaymentMethod = {
  id?: string;
  name?: string;
};

const props = defineProps<{
  view: View;
  period: Range;
  category: CategoryName | undefined;
  cashier: Cashier | undefined;
  productIds?: string;
  paymentMethod: PaymentMethod | undefined; // Add this line
}>();

const emit = defineEmits<{
  "update:cashier": [value: { id: string; name: string } | undefined];
}>();

const storeId = computed(() => useCurrentStore().value?._id);
const periodStart = computed(() => props.period.start.toISOString());
const periodEnd = computed(() => props.period.end.toISOString());
const category = computed(() => props.category);
const productIds = computed(() => props.productIds);

const paymentMethodId = computed(() => props.paymentMethod?.id); // Add this line

const cashierQuery = {
  periodStart,
  periodEnd,
  productIds,
  category,
  storeId,
  paymentMethodId, // Add this line
};

const { data: saleCountByCashier, status: saleCountByCashierStatus } =
  await useLazyFetch("/api/sales/sale-count/by-cashier", {
    transform: (data) => saleCountByCashierOutputSchema.parse(data),
    query: cashierQuery,
    server: false,
  });
const { data: salesRevenueByCashier, status: salesRevenueByCashierStatus } =
  await useLazyFetch("/api/sales/sales-revenue/by-cashier", {
    transform: (data) => salesRevenueByCashierOutputSchema.parse(data),
    query: cashierQuery,
    server: false,
  });
const {
  data: averageRevenuePerSaleByCashier,
  status: averageRevenuePerSaleByCashierStatus,
} = await useLazyFetch("/api/sales/average-revenue-per-sale/by-cashier", {
  transform: (data) => averageRevenueByCashierOutputSchema.parse(data),
  query: cashierQuery,
  server: false,
});

const currentStore = useCurrentStore();

const route = useRoute();

async function toggleCashier({ id, name }: { id: string; name: string }) {
  if (props.cashier?.id === id) {
    deleteCashier();
    return;
  }

  emit("update:cashier", { id, name });
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      cashierId: id,
      cashierName: name,
    },
  });
}

async function deleteCashier() {
  emit("update:cashier", undefined);
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      cashierId: undefined,
      cashierName: undefined,
    },
  });
}

const dataPending = computed(() => {
  switch (props.view) {
    case "sales":
      return (
        saleCountByCashierStatus.value === "idle" ||
        saleCountByCashierStatus.value === "pending"
      );
    case "revenue":
      return (
        salesRevenueByCashierStatus.value === "idle" ||
        salesRevenueByCashierStatus.value === "pending"
      );
    case "average-revenue":
      return (
        averageRevenuePerSaleByCashierStatus.value === "idle" ||
        averageRevenuePerSaleByCashierStatus.value === "pending"
      );
    default:
      return false;
  }
});

const { t, locale } = useI18n({
  useScope: "local",
});

const { isScrolling } = useScroll(window);
</script>

<template>
  <div
    class="overflow-hidden rounded-xl bg-surface-container-lowest outline outline-1 outline-outline/20"
  >
    <div
      class="flex items-center justify-between border-b border-outline/40 p-5 sm:p-6"
    >
      <div class="relative grow">
        <div
          class="flex items-center gap-2 transition-transform delay-0 duration-150 ease-in-out"
          :class="cashier?.id && 'translate-y-[-45%]'"
        >
          <p class="font-medium">
            {{ t("title") }}
          </p>
          <UIPopover>
            <UIPopoverTrigger as-child>
              <button
                class="flex items-center rounded p-1 hover:bg-on-surface/[.08] focus-visible:bg-on-surface/[.08] focus-visible:outline-none"
                aria-label="Info"
              >
                <Icon
                  name="mdi:information"
                  class="size-4 text-on-surface/50"
                />
              </button>
            </UIPopoverTrigger>
            <UIPopoverContent class="w-fit max-w-[32ch]">
              <p v-if="locale === 'fr'" class="text-sm">
                {{
                  view === "revenue"
                    ? "Revenus"
                    : view === "sales"
                      ? "Nombre de ventes"
                      : "Revenu moyen par vente"
                }}
                <!-- <span v-if="productName">
                  pour le produit
                  <span class="font-medium first-letter:capitalize">{{
                    productName
                  }}</span>
                </span>
                <span v-if="category">
                  de categorie
                  <span class="font-medium">{{ category }}</span>
                </span> -->
                effectuées par chaque caissier
              </p>

              <p v-else class="text-sm">
                {{
                  view === "revenue"
                    ? "Revenue"
                    : view === "sales"
                      ? "Sales count"
                      : "Average revenue per sale"
                }}
                <!-- <span v-if="productName">
                  for the productName
                  <span class="font-medium first-letter:capitalize">{{
                    productName
                  }}</span>
                </span>
                <span v-if="category">
                  of category
                  <span class="font-medium">{{ category }}</span>
                </span> -->
                made by each cashier
              </p>
            </UIPopoverContent>
          </UIPopover>
        </div>

        <button
          v-if="cashier?.id"
          @click="deleteCashier"
          style="
            transition:
              transform 0.15s ease,
              opacity 0.15s ease;
            transform: translateY(60%);
            transition-delay: 0.01s;
          "
          class="absolute left-[-7px] top-0 flex items-center gap-1 rounded-lg px-2 py-1 text-sm text-secondary hover:bg-on-surface/[.08] hover:text-primary"
          :class="cashier?.id && 'translate-y-[60%] opacity-100'"
        >
          <span>{{ t("clearFilter") }}</span>
          <Icon name="mdi:close" class="size-4" />
        </button>
      </div>

      <p class="text-sm uppercase text-on-surface/80">
        {{ t(view) }}
      </p>
    </div>
    <div class="h-[12.5rem] overflow-auto px-2 py-4">
      <ul v-if="dataPending" class="space-y-4 px-4 py-2">
        <li v-for="i in 3" :key="i">
          <UISkeleton class="h-10 w-full" />
        </li>
      </ul>

      <ul v-else-if="view === 'sales' && saleCountByCashier" class="space-y-5">
        <li v-for="sale in saleCountByCashier" :key="sale._id">
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="() => toggleCashier({ id: sale._id, name: sale.name })"
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="cashier?.id === sale._id && 'bg-on-surface/[.08]'"
              >
                <div class="flex items-center gap-2">
                  <span>
                    {{ sale.name }}
                  </span>
                  <span
                    v-if="
                      currentStore?.positiveTrendThreshold !== undefined &&
                      currentStore?.positiveTrendThreshold !== undefined
                    "
                  >
                    <SalesTrendIndicator
                      :trend="sale.trend"
                      :positiveTrendThreshold="
                        currentStore?.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore?.negativeTrendThreshold
                      "
                    />
                  </span>
                </div>

                <span class="font-medium">
                  {{ numberFormat(sale.value) }}
                </span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p v-if="locale === 'fr'">
                  Cliquer pour
                  {{ cashier?.id === sale._id ? "effacer" : "filtrer" }}
                </p>

                <p v-if="locale === 'en'">
                  Click to
                  {{ cashier?.id === sale._id ? "clear" : "filter" }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>

      <ul
        v-else-if="
          view === 'revenue' &&
          salesRevenueByCashier &&
          salesRevenueByCashier.length > 0
        "
        class="space-y-5"
      >
        <li v-for="sale in salesRevenueByCashier" :key="sale._id">
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="() => toggleCashier({ id: sale._id, name: sale.name })"
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="cashier?.id === sale._id && 'bg-on-surface/[.08]'"
              >
                <div class="flex items-center gap-2">
                  <span>
                    {{ sale.name }}
                  </span>
                  <span
                    v-if="
                      currentStore?.positiveTrendThreshold !== undefined &&
                      currentStore?.positiveTrendThreshold !== undefined
                    "
                  >
                    <SalesTrendIndicator
                      :trend="sale.trend"
                      :positiveTrendThreshold="
                        currentStore?.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore?.negativeTrendThreshold
                      "
                    />
                  </span>
                </div>

                <span class="font-medium">
                  {{ numberFormat(sale.value) }}
                </span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p v-if="locale === 'fr'">
                  Cliquer pour
                  {{ cashier?.id === sale._id ? "effacer" : "filtrer" }}
                </p>

                <p v-if="locale === 'en'">
                  Click to
                  {{ cashier?.id === sale._id ? "clear" : "filter" }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>

      <ul
        v-else-if="
          view === 'average-revenue' &&
          averageRevenuePerSaleByCashier &&
          averageRevenuePerSaleByCashier.length > 0
        "
        class="space-y-5"
      >
        <li v-for="sale in averageRevenuePerSaleByCashier" :key="sale._id">
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="() => toggleCashier({ id: sale._id, name: sale.name })"
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="cashier?.id === sale._id && 'bg-on-surface/[.08]'"
              >
                <div class="flex items-center gap-2">
                  <span>
                    {{ sale.name }}
                  </span>
                  <span
                    v-if="
                      currentStore?.positiveTrendThreshold !== undefined &&
                      currentStore?.positiveTrendThreshold !== undefined
                    "
                  >
                    <SalesTrendIndicator
                      :trend="sale.trend"
                      :positiveTrendThreshold="
                        currentStore?.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore?.negativeTrendThreshold
                      "
                    />
                  </span>
                </div>

                <span class="font-medium">
                  {{ averageRevenueFormat(sale.value) }}
                </span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p v-if="locale === 'fr'">
                  Cliquer pour
                  {{ cashier?.id === sale._id ? "effacer" : "filtrer" }}
                </p>

                <p v-if="locale === 'en'">
                  Click to
                  {{ cashier?.id === sale._id ? "clear" : "filter" }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Cashiers",
    "sales": "Sales",
    "revenue": "Revenue",
    "average-revenue": "Average Revenue",
    "clearFilter": "Clear Filter"
  },
  "fr": {
    "title": "Caissiers",
    "sales": "Ventes",
    "revenue": "Revenu",
    "average-revenue": "Revenu Moyen",
    "clearFilter": "Supprimer Filtre"
  }
}
</i18n>
