<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useScroll } from "@vueuse/core";
import {
  averageRevenueByProductOutputSchema,
  quantitySoldByProductOutputSchema,
  salesRevenueByProductOutputSchema,
} from "~/validations/sale";
import type { CategoryName } from "~/server/constants/product";
import type { Range, View } from "~/server/constants/sale";

type Product = {
  id: string;
  name: string;
};

type Cashier = {
  id?: string;
  name?: string;
};

type PaymentMethod = {
  id?: string;
  name?: string;
};

type Props = {
  view: View;
  period: Range;
  category?: CategoryName;
  products?: Product[];
  cashier?: Cashier;
  paymentMethod?: PaymentMethod;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  "update:products": [value: Product[] | undefined];
}>();

const { t } = useI18n({ useScope: "local" });
const route = useRoute();
const router = useRouter();
const currentStore = useCurrentStore();

const storeId = computed(() => currentStore.value?._id);
const periodStart = computed(() => props.period.start.toISOString());
const periodEnd = computed(() => props.period.end.toISOString());
const category = computed(() => props.category);
const cashierId = computed(() => props.cashier?.id);
const paymentMethodId = computed(() => props.paymentMethod?.id);
const { isMobile } = useDevice();

const topProductsQuery = computed(() => ({
  periodStart: periodStart.value,
  periodEnd: periodEnd.value,
  category: category.value,
  cashierId: cashierId.value,
  storeId: storeId.value,
  paymentMethodId: paymentMethodId.value,
  limit: isMobile ? 10 : 15,
}));

const {
  data: topProductsByQuantitySold,
  status: topProductsByQuantitySoldStatus,
} = useLazyFetch("/api/sales/quantity-sold/by-product", {
  transform: (data) => quantitySoldByProductOutputSchema.parse(data),
  query: topProductsQuery,
  server: false,
});

const {
  data: topProductsBySalesRevenue,
  status: topProductsBySalesRevenueStatus,
} = useLazyFetch("/api/sales/sales-revenue/by-product", {
  transform: (data) => salesRevenueByProductOutputSchema.parse(data),
  query: topProductsQuery,
  server: false,
});

const {
  data: topProductsByAverageRevenuePerSale,
  status: topProductsByAverageRevenuePerSaleStatus,
} = useLazyFetch("/api/sales/average-revenue-per-sale/by-product", {
  transform: (data) => averageRevenueByProductOutputSchema.parse(data),
  query: topProductsQuery,
  server: false,
});

const isProductsDialogOpen = ref(false);

const { isScrolling } = useScroll(window);

const dataPending = computed(() => {
  switch (props.view) {
    case "sales":
      return (
        topProductsByQuantitySoldStatus.value === "idle" ||
        topProductsByQuantitySoldStatus.value === "pending"
      );
    case "revenue":
      return (
        topProductsBySalesRevenueStatus.value === "idle" ||
        topProductsBySalesRevenueStatus.value === "pending"
      );
    case "average-revenue":
      return (
        topProductsByAverageRevenuePerSaleStatus.value === "idle" ||
        topProductsByAverageRevenuePerSaleStatus.value === "pending"
      );
    default:
      return false;
  }
});

const shouldShowViewAllButton = computed(() => {
  switch (props.view) {
    case "sales":
      return (
        topProductsByQuantitySold.value?.length === topProductsQuery.value.limit
      );
    case "revenue":
      return (
        topProductsBySalesRevenue.value?.length === topProductsQuery.value.limit
      );
    case "average-revenue":
      return (
        topProductsByAverageRevenuePerSale.value?.length ===
        topProductsQuery.value.limit
      );
    default:
      return false;
  }
});

async function toggleProduct({ id, name }: Product) {
  let newProducts: Product[] | undefined;

  if (props.products?.find((product) => product.id === id)) {
    newProducts = props.products.filter((product) => product.id !== id);
  } else {
    newProducts = props.products
      ? [...props.products, { id, name }]
      : [{ id, name }];
  }

  emit("update:products", newProducts);

  await router.push({
    path: route.path,
    query: {
      ...route.query,
      products: newProducts.length ? JSON.stringify(newProducts) : undefined,
    },
  });
}

async function deleteProducts() {
  emit("update:products", undefined);
  await router.push({
    path: route.path,
    query: {
      ...route.query,
      products: undefined,
    },
  });
}
</script>

<template>
  <div
    class="flex h-full flex-col rounded-xl bg-surface-container-lowest outline outline-1 outline-outline/20"
  >
    <div
      class="flex items-center justify-between border-b border-outline/40 p-5 sm:p-6"
    >
      <div class="relative grow">
        <div
          class="flex items-center gap-2 transition-transform delay-0 duration-150 ease-in-out"
          :class="products && products.length > 0 && 'translate-y-[-45%]'"
        >
          <p class="font-medium">{{ t("title") }}</p>
          <UIPopover>
            <UIPopoverTrigger as-child>
              <button
                class="flex items-center rounded p-1 hover:bg-on-surface/[.08] focus-visible:bg-on-surface/[.08] focus-visible:outline-none"
                aria-label="Info"
              >
                <Icon
                  name="mdi:information"
                  class="size-4 text-on-surface/50"
                />
              </button>
            </UIPopoverTrigger>
            <UIPopoverContent class="w-[32ch]">
              <p class="text-sm">
                {{ t("infoText", { category, cashierName: cashier?.name }) }}
              </p>
            </UIPopoverContent>
          </UIPopover>
        </div>
        <button
          v-if="products && products.length > 0"
          @click="deleteProducts"
          class="absolute left-[-7px] top-0 flex items-center gap-1 rounded-lg px-2 py-1 text-sm text-secondary hover:bg-on-surface/[.08] hover:text-primary"
          :class="category && 'translate-y-[60%] opacity-100'"
          style="
            transition:
              transform 0.15s ease,
              opacity 0.15s ease;
            transform: translateY(60%);
            transition-delay: 0.01s;
          "
        >
          <span>{{ t("clearFilter") }}</span>
          <Icon name="mdi:close" class="size-4" />
        </button>
      </div>
      <p class="text-sm uppercase text-on-surface/80">
        {{ t(view) }}
      </p>
    </div>

    <div class="relative flex grow flex-col overflow-hidden">
      <div v-if="dataPending" class="grow space-y-4 px-4 py-2">
        <div v-for="i in topProductsQuery.limit" :key="i">
          <UISkeleton class="h-10 w-full" />
        </div>
      </div>

      <ul
        v-else-if="
          view === 'sales' &&
          topProductsByQuantitySold &&
          topProductsByQuantitySold.length > 0
        "
        class="grow space-y-5 overflow-y-auto px-4 py-2"
      >
        <SalesTopProductsItem
          v-for="product in topProductsByQuantitySold"
          :key="product._id"
          :product="product"
          :selected="!!products?.find((p) => p.id === product._id)"
          @toggle-product="toggleProduct"
        />
      </ul>

      <ul
        v-else-if="
          view === 'revenue' &&
          topProductsBySalesRevenue &&
          topProductsBySalesRevenue.length > 0
        "
        class="grow space-y-5 overflow-y-auto px-4 py-2"
      >
        <li
          v-for="{
            _id,
            name,
            imageUrl,
            value,
            trend,
          } in topProductsBySalesRevenue"
          :key="_id"
        >
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="() => toggleProduct({ id: _id, name })"
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="
                  products?.find((product) => product.id === _id) &&
                  'bg-on-surface/[.08]'
                "
              >
                <div class="flex items-center gap-4">
                  <NuxtImg
                    :src="`/products/${imageUrl}`"
                    :alt="name"
                    class="size-6 shrink-0 object-contain"
                    sizes="24px"
                  />
                  <div class="flex items-center gap-2">
                    <span>{{ name }}</span>
                    <SalesTrendIndicator
                      v-if="
                        currentStore?.positiveTrendThreshold !== undefined &&
                        currentStore?.negativeTrendThreshold !== undefined
                      "
                      :trend="trend"
                      :positiveTrendThreshold="
                        currentStore.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore.negativeTrendThreshold
                      "
                    />
                  </div>
                </div>
                <span class="font-medium">{{ numberFormat(value) }}</span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p>
                  {{
                    t(
                      products?.find((product) => product.id === _id)
                        ? "clickToClear"
                        : "clickToFilter",
                    )
                  }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>

      <ul
        v-else-if="
          view === 'average-revenue' &&
          topProductsByAverageRevenuePerSale &&
          topProductsByAverageRevenuePerSale.length > 0
        "
        class="grow space-y-5 overflow-y-auto px-4 py-2"
      >
        <li
          v-for="{
            _id,
            name,
            imageUrl,
            value,
            trend,
          } in topProductsByAverageRevenuePerSale"
          :key="_id"
        >
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="() => toggleProduct({ id: _id, name })"
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="
                  products?.find((product) => product.id === _id) &&
                  'bg-on-surface/[.08]'
                "
              >
                <div class="flex items-center gap-4">
                  <NuxtImg
                    :src="`/products/${imageUrl}`"
                    :alt="name"
                    class="size-6 shrink-0 object-contain"
                    sizes="24px"
                  />
                  <div class="flex items-center gap-2">
                    <span>{{ name }}</span>
                    <SalesTrendIndicator
                      v-if="
                        currentStore?.positiveTrendThreshold !== undefined &&
                        currentStore?.negativeTrendThreshold !== undefined
                      "
                      :trend="trend"
                      :positiveTrendThreshold="
                        currentStore.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore.negativeTrendThreshold
                      "
                    />
                  </div>
                </div>
                <span class="font-medium">{{
                  averageRevenueFormat(value)
                }}</span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p>
                  {{
                    t(
                      products?.find((product) => product.id === _id)
                        ? "clickToClear"
                        : "clickToFilter",
                    )
                  }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>

      <div
        class="pointer-events-none absolute inset-x-0 bottom-0 h-20 rounded-b-xl bg-gradient-to-t from-surface-container-lowest to-surface-container-lowest/0"
        v-if="shouldShowViewAllButton"
      />

      <UIDialogRoot
        v-if="shouldShowViewAllButton"
        v-model:open="isProductsDialogOpen"
      >
        <UIDialogTrigger as-child>
          <button
            class="absolute bottom-3 left-1/2 flex w-fit -translate-x-1/2 items-center gap-2 whitespace-nowrap rounded-full bg-surface-container-lowest px-3 py-1 outline outline-1 outline-outline/30 hover:bg-on-surface/[0.08]"
          >
            <span class="text-sm">{{ t("viewAll") }}</span>
            <Icon name="mdi:arrow-expand" class="size-4" />
          </button>
        </UIDialogTrigger>

        <UIDialogContent class="gap-0 rounded-[30px] p-1">
          <UIDialogHeader>
            <VisuallyHidden asChild>
              <UIDialogTitle>{{ t("title") }}</UIDialogTitle>
            </VisuallyHidden>
            <VisuallyHidden asChild>
              <UIDialogDescription>{{ t(view) }}</UIDialogDescription>
            </VisuallyHidden>
          </UIDialogHeader>

          <LazySalesAllProducts
            :view="view"
            :category="category"
            :period="period"
            :cashier="cashier"
            :products="products"
            @update:products="
              (value: Product[] | undefined) => emit('update:products', value)
            "
            @close-modal="isProductsDialogOpen = false"
          />

          <UIDialogFooter>
            <UIDialogClose as-child>
              <UIButton :label="t('close')" variant="outlined" class="w-full" />
            </UIDialogClose>
          </UIDialogFooter>
        </UIDialogContent>
      </UIDialogRoot>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Top Products",
    "revenue": "Revenue",
    "quantitySold": "quantity sold",
    "average-revenue": "average revenue",
    "viewAll": "View All",
    "goToProduct": "Go to \"{name}\"",
    "clearFilter": "Clear Filter",
    "close": "Close",
    "sales": "Sales"
  },
  "fr": {
    "title": "Top Produits",
    "sales": "Ventes",
    "revenue": "Revenu",
    "quantitySold": "quantité vendue",
    "average-revenue": "revenu moyen",
    "viewAll": "Voir Tout",
    "goToProduct": "Voir {name}",
    "clearFilter": "Supprimer Filtre",
    "close": "Fermer"
  }
}
</i18n>
