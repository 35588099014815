<script setup lang="ts">
import type { View, Range } from "~/server/constants/sale";
import {
  saleCountByPaymentMethodOutputSchema,
  salesRevenueByPaymentMethodOutputSchema,
  averageRevenueByPaymentMethodOutputSchema,
} from "~/validations/sale";
import type { CategoryName } from "~/server/constants/product";

type PaymentMethod = {
  id?: string;
  name?: string;
};
type Cashier = {
  id?: string;
  name?: string;
};

const props = defineProps<{
  view: View;
  period: Range;
  category: CategoryName | undefined;
  cashier: Cashier | undefined;
  productIds?: string;
  paymentMethod: PaymentMethod | undefined;
}>();

const emit = defineEmits<{
  "update:paymentMethod": [value: PaymentMethod | undefined];
}>();

const storeId = computed(() => useCurrentStore().value?._id);
const periodStart = computed(() => props.period.start.toISOString());
const periodEnd = computed(() => props.period.end.toISOString());
const category = computed(() => props.category);
const cashierId = computed(() => props.cashier?.id);
const productIds = computed(() => props.productIds);

const query = {
  storeId,
  periodStart,
  periodEnd,
  category,
  cashierId,
  productIds,
};

const {
  data: saleCountByPaymentMethod,
  status: saleCountByPaymentMethodStatus,
} = await useLazyFetch("/api/sales/sale-count/by-payment-method", {
  transform: (data) => saleCountByPaymentMethodOutputSchema.parse(data),
  query,
  server: false,
});
const {
  data: salesRevenueByPaymentMethod,
  status: salesRevenueByPaymentMethodStatus,
} = await useLazyFetch("/api/sales/sales-revenue/by-payment-method", {
  transform: (data) => salesRevenueByPaymentMethodOutputSchema.parse(data),
  query,
  server: false,
});
const {
  data: averageRevenuePerSaleByPaymentMethod,
  status: averageRevenuePerSaleByPaymentMethodStatus,
} = await useLazyFetch(
  "/api/sales/average-revenue-per-sale/by-payment-method",
  {
    transform: (data) => averageRevenueByPaymentMethodOutputSchema.parse(data),
    query,
    server: false,
  },
);

const currentStore = useCurrentStore();

const route = useRoute();

async function togglePaymentMethod({ id, name }: PaymentMethod) {
  if (props.paymentMethod?.id === id) {
    deletePaymentMethod();
    return;
  }

  emit("update:paymentMethod", { id, name });
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      paymentMethodId: id,
      paymentMethodName: name,
    },
  });
}

async function deletePaymentMethod() {
  emit("update:paymentMethod", undefined);
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      paymentMethodId: undefined,
      paymentMethodName: undefined,
    },
  });
}

const dataPending = computed(() => {
  switch (props.view) {
    case "sales":
      return (
        saleCountByPaymentMethodStatus.value === "idle" ||
        saleCountByPaymentMethodStatus.value === "pending"
      );
    case "revenue":
      return (
        salesRevenueByPaymentMethodStatus.value === "idle" ||
        salesRevenueByPaymentMethodStatus.value === "pending"
      );
    case "average-revenue":
      return (
        averageRevenuePerSaleByPaymentMethodStatus.value === "idle" ||
        averageRevenuePerSaleByPaymentMethodStatus.value === "pending"
      );
    default:
      return false;
  }
});

const { t, locale } = useI18n({
  useScope: "local",
});

const { isScrolling } = useScroll(window);
</script>

<template>
  <div
    class="overflow-hidden rounded-xl bg-surface-container-lowest outline outline-1 outline-outline/20"
  >
    <div
      class="flex items-center justify-between border-b border-outline/40 p-5 sm:p-6"
    >
      <div class="relative grow">
        <div
          class="flex items-center gap-2 transition-transform delay-0 duration-150 ease-in-out"
          :class="paymentMethod?.id && 'translate-y-[-45%]'"
        >
          <p class="font-medium">
            {{ t("title") }}
          </p>
          <UIPopover>
            <UIPopoverTrigger as-child>
              <button
                class="flex items-center rounded p-1 hover:bg-on-surface/[.08] focus-visible:bg-on-surface/[.08] focus-visible:outline-none"
                aria-label="Info"
              >
                <Icon
                  name="mdi:information"
                  class="size-4 text-on-surface/50"
                />
              </button>
            </UIPopoverTrigger>
            <UIPopoverContent class="w-fit max-w-[32ch]">
              <p v-if="locale === 'fr'" class="text-sm">
                {{
                  view === "revenue"
                    ? "Revenus"
                    : view === "sales"
                      ? "Nombre de ventes"
                      : "Revenu moyen par vente"
                }}
                effectuées par chaque méthode de paiement
              </p>

              <p v-else class="text-sm">
                {{
                  view === "revenue"
                    ? "Revenue"
                    : view === "sales"
                      ? "Sales count"
                      : "Average revenue per sale"
                }}
                made by each payment method
              </p>
            </UIPopoverContent>
          </UIPopover>
        </div>

        <button
          v-if="paymentMethod?.id"
          @click="deletePaymentMethod"
          style="
            transition:
              transform 0.15s ease,
              opacity 0.15s ease;
            transform: translateY(60%);
            transition-delay: 0.01s;
          "
          class="absolute left-[-7px] top-0 flex items-center gap-1 rounded-lg px-2 py-1 text-sm text-secondary hover:bg-on-surface/[.08] hover:text-primary"
          :class="paymentMethod?.id && 'translate-y-[60%] opacity-100'"
        >
          <span>{{ t("clearFilter") }}</span>
          <Icon name="mdi:close" class="size-4" />
        </button>
      </div>

      <p class="text-sm uppercase text-on-surface/80">
        {{ t(view) }}
      </p>
    </div>
    <div class="h-[12.5rem] overflow-auto px-2 py-4">
      <ul v-if="dataPending" class="space-y-4 px-4 py-2">
        <li v-for="i in 3" :key="i">
          <UISkeleton class="h-10 w-full" />
        </li>
      </ul>

      <ul
        v-else-if="view === 'sales' && saleCountByPaymentMethod"
        class="space-y-5"
      >
        <li v-for="sale in saleCountByPaymentMethod" :key="sale._id">
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="
                  () => togglePaymentMethod({ id: sale._id, name: sale.name })
                "
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="paymentMethod?.id === sale._id && 'bg-on-surface/[.08]'"
              >
                <div class="flex items-center gap-2">
                  <span>
                    {{ sale.name }}
                  </span>
                  <span
                    v-if="
                      currentStore?.positiveTrendThreshold !== undefined &&
                      currentStore?.positiveTrendThreshold !== undefined
                    "
                  >
                    <SalesTrendIndicator
                      :trend="sale.trend"
                      :positiveTrendThreshold="
                        currentStore?.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore?.negativeTrendThreshold
                      "
                    />
                  </span>
                </div>

                <span class="font-medium">
                  {{ numberFormat(sale.value) }}
                </span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p v-if="locale === 'fr'">
                  Cliquer pour
                  {{ paymentMethod?.id === sale._id ? "effacer" : "filtrer" }}
                </p>

                <p v-if="locale === 'en'">
                  Click to
                  {{ paymentMethod?.id === sale._id ? "clear" : "filter" }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>

      <ul
        v-else-if="
          view === 'revenue' &&
          salesRevenueByPaymentMethod &&
          salesRevenueByPaymentMethod.length > 0
        "
        class="space-y-5"
      >
        <li v-for="sale in salesRevenueByPaymentMethod" :key="sale._id">
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="
                  () => togglePaymentMethod({ id: sale._id, name: sale.name })
                "
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="paymentMethod?.id === sale._id && 'bg-on-surface/[.08]'"
              >
                <div class="flex items-center gap-2">
                  <span>
                    {{ sale.name }}
                  </span>
                  <span
                    v-if="
                      currentStore?.positiveTrendThreshold !== undefined &&
                      currentStore?.positiveTrendThreshold !== undefined
                    "
                  >
                    <SalesTrendIndicator
                      :trend="sale.trend"
                      :positiveTrendThreshold="
                        currentStore?.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore?.negativeTrendThreshold
                      "
                    />
                  </span>
                </div>

                <span class="font-medium">
                  {{ numberFormat(sale.value) }}
                </span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p v-if="locale === 'fr'">
                  Cliquer pour
                  {{ paymentMethod?.id === sale._id ? "effacer" : "filtrer" }}
                </p>

                <p v-if="locale === 'en'">
                  Click to
                  {{ paymentMethod?.id === sale._id ? "clear" : "filter" }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>

      <ul
        v-else-if="
          view === 'average-revenue' &&
          averageRevenuePerSaleByPaymentMethod &&
          averageRevenuePerSaleByPaymentMethod.length > 0
        "
        class="space-y-5"
      >
        <li
          v-for="sale in averageRevenuePerSaleByPaymentMethod"
          :key="sale._id"
        >
          <UITooltipProvider>
            <UITooltip>
              <UITooltipTrigger
                @click="
                  () => togglePaymentMethod({ id: sale._id, name: sale.name })
                "
                :disabled="isScrolling"
                class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
                :class="paymentMethod?.id === sale._id && 'bg-on-surface/[.08]'"
              >
                <div class="flex items-center gap-2">
                  <span>
                    {{ sale.name }}
                  </span>
                  <span
                    v-if="
                      currentStore?.positiveTrendThreshold !== undefined &&
                      currentStore?.positiveTrendThreshold !== undefined
                    "
                  >
                    <SalesTrendIndicator
                      :trend="sale.trend"
                      :positiveTrendThreshold="
                        currentStore?.positiveTrendThreshold
                      "
                      :negativeTrendThreshold="
                        currentStore?.negativeTrendThreshold
                      "
                    />
                  </span>
                </div>

                <span class="font-medium">
                  {{ averageRevenueFormat(sale.value) }}
                </span>
              </UITooltipTrigger>
              <UITooltipContent>
                <p v-if="locale === 'fr'">
                  Cliquer pour
                  {{ paymentMethod?.id === sale._id ? "effacer" : "filtrer" }}
                </p>

                <p v-if="locale === 'en'">
                  Click to
                  {{ paymentMethod?.id === sale._id ? "clear" : "filter" }}
                </p>
              </UITooltipContent>
            </UITooltip>
          </UITooltipProvider>
        </li>
      </ul>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Payment Methods",
    "sales": "Sales",
    "revenue": "Revenue",
    "average-revenue": "Average Revenue",
    "clearFilter": "Clear Filter"
  },
  "fr": {
    "title": "Méthodes de paiement",
    "sales": "Ventes",
    "revenue": "Revenu",
    "average-revenue": "Revenu Moyen",
    "clearFilter": "Supprimer Filtre"
  }
}
</i18n>
