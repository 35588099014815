<script setup lang="ts">
import type { quantitySoldByProductOutputSchema } from "~/validations/sale";

defineProps<{
  product: output<typeof quantitySoldByProductOutputSchema>[number];
  selected: boolean;
}>();

const emit = defineEmits<{
  "toggle-product": [
    value: {
      id: string;
      name: string;
    },
  ];
}>();

const currentStore = useCurrentStore();

const showGoTo = ref(false);
const { isScrolling } = useScroll(window);

const { t, locale } = useI18n({
  useScope: "local",
});
</script>

<template>
  <li>
    <UITooltipProvider>
      <UITooltip>
        <UITooltipTrigger
          @click="
            emit('toggle-product', { id: product._id, name: product.name })
          "
          :disabled="isScrolling"
          class="flex w-full items-center justify-between rounded-md px-4 py-2 hover:bg-on-surface/[.08]"
          :class="selected && 'bg-on-surface/[.08]'"
        >
          <div class="flex items-center gap-4">
            <NuxtImg
              :src="`/products/${product.imageUrl}`"
              :alt="product.name"
              class="size-6 shrink-0 object-contain"
              sizes="24px"
              crossorigin
            />
            <div class="flex items-center gap-2">
              <!-- <NuxtLink
                :to="{
                  path: `/app/products/${product._id}`,
                  query: {
                    redirectedFrom: $route.fullPath,
                  },
                }"
              > -->
              <!-- @mouseenter="showGoTo = true"
              @mouseleave="showGoTo = false"
              @click="$event.stopPropagation()"
              class="hover:underline" -->
              <span>
                {{ product.name }}
              </span>
              <!-- </NuxtLink> -->

              <span
                v-if="
                  currentStore?.positiveTrendThreshold !== undefined &&
                  currentStore?.positiveTrendThreshold !== undefined
                "
              >
                <SalesTrendIndicator
                  :trend="product.trend"
                  :positiveTrendThreshold="currentStore?.positiveTrendThreshold"
                  :negativeTrendThreshold="currentStore?.negativeTrendThreshold"
                />
              </span>
            </div>
          </div>

          <span class="font-medium">
            {{ numberFormat(product.value) }}
            {{ product.unit }}
          </span>
        </UITooltipTrigger>
        <UITooltipContent>
          <p v-if="locale === 'fr'">
            {{
              showGoTo
                ? t("goToProduct", { name: product.name })
                : selected
                  ? "Cliquer pour effacer"
                  : "Cliquer pour filtrer"
            }}
          </p>

          <p v-if="locale === 'en'">
            {{
              showGoTo
                ? t("goToProduct", { name: product.name })
                : selected
                  ? "Click to clear"
                  : "Click to filter"
            }}
          </p>
        </UITooltipContent>
      </UITooltip>
    </UITooltipProvider>
  </li>
</template>
