<script setup lang="ts" generic="T">
import type { ApexOptions } from "apexcharts";

import fr from "apexcharts/dist/locales/fr.json";
import { format, subHours } from "date-fns";
import { fr as french } from "date-fns/locale";
import ApexChart from "vue3-apexcharts";

export interface Props<T> {
  height?: string;
  series: T[];
  isToday: boolean;
  showFraction: boolean;
}

const props = withDefaults(defineProps<Props<T>>(), {
  height: "350",
});

const { locale } = useI18n({
  useScope: "local",
});

const colorMode = useColorMode();

const options: ApexOptions = {
  chart: {
    id: "sales_totalAmount",
    fontFamily: "Poppins",
    locales: [fr],
    defaultLocale: "fr",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeUTC: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    theme: colorMode.value,
    marker: {
      show: false,
    },
    hideEmptySeries: false,
  },
};
</script>

<template>
  <ApexChart
    type="area"
    :height="props.height"
    :options="{
      ...options,
      xaxis: {
        ...options.xaxis,
        labels: {
          ...options.xaxis?.labels,
          format: props.isToday ? 'HH:mm' : 'dd MMM',
        },
      },
      yaxis: {
        labels: {
          formatter: function (value: number) {
            return showFraction
              ? value === 0
                ? 0
                : value.toFixed(2)
              : value.toFixed(0);
          },
        },
      },
      tooltip: {
        ...options.tooltip,
        x: {
          formatter: (value: number) => {
            return props.isToday
              ? format(subHours(value, 1), 'HH:mm') +
                  ' - ' +
                  format(value, 'HH:mm') +
                  ` (${format(value, 'dd MMM', {
                    locale: locale === 'fr' ? french : undefined,
                  })})`
              : format(value, 'dd MMM yyyy', {
                  locale: locale === 'fr' ? french : undefined,
                });
          },
        },
      },
    }"
    :series="props.series"
  />
</template>

<i18n lang="json">
{
  "en": {
    "nodata": "No data available"
  },
  "fr": {
    "nodata": "Aucune donnée trouvée"
  }
}
</i18n>
